import { Typography, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import Layout from '../../components/layout'

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(5),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        gap: 20,
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2),

        }
    },
    list: {
        listStyleType: 'none;',
        marginLeft: 0,
        paddingLeft: '10em;',
        textIndent: '-5em;',
        [theme.breakpoints.down('md')]:{
            paddingLeft: '1em;',
        textIndent: '-1em;',
        }
    }

}))

const TermsOfUse = ({ location }) => {
    const classes = useStyles()

    return (<Layout nodeLocale='en-EU' location={location} title='Terms of Use'>
        <Grid container className={classes.root}>
            <Typography component='h1' variant='h3'>Terms of Use</Typography>

            <Typography variant='body1'>
                Access to and use of this Atlaspet A.Ş. site is subject to the following terms and conditions and the laws of Republic of Turkey. These Terms of Use constitute an agreement between Atlaspet A.Ş. and you, the visitor, governing your access and use of the Website. Please read these Terms of Use carefully before you start using the Website.
            </Typography>
            <Typography variant='body1'>
                You agree to defend, indemnify, and hold harmless Atlaspet and its agents, managers, affiliates, licensors, licensees, successors and assigns and each of their respective directors, officers, contractors, employees, agents, service providers and partners (“Indemnified Parties”), from and against any demands, losses, cost or debt, liabilities, claims or expenses (including reasonable attorneys’ fees) made against Atlaspet by any third party due to or arising out of or in connection with
            </Typography>
            <ul className={classes.list}>
                <li>(i) your use of and access to the Website, including any data or content transmitted or received by you;</li> <li>(ii) your violation of any term of these Terms of Use; </li><li>(iii) your violation of any third-party right, including without limitation any right of privacy, right of publicity or intellectual property rights;</li> <li>(iv) your violation of any applicable law, rule or regulation;</li> <li>(v) your User Generated Material or any that is submitted via your account; or</li> <li>(vi) any other party’s access and use of the Website with your unique username, password, or other appropriate security code.</li>
            </ul>
            <Typography variant='body1'>
                Without limiting anything set out above, you hereby release each of the Indemnified Parties from all damages, liabilities, claims, actions, demands and costs of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with these Terms of Use, the Privacy Policy and/or any use by you of the Website.
            </Typography>
            <Typography variant='body1'>
                The Website may contain links to other websites, including, but not limited to, advertisements and other content posted by us or by third-parties (“Linked Sites”). You may also navigate to the Website from links, often provided by us, deployed by third parties in their social media, advertisements and other marketing activities (the locations where these links are placed are also considered Linked Sites). Access to or from Linked Sites is made available by Atlaspet only as a convenience. Such Linked Sites are not owned, operated or controlled by us and if you visit Linked Sites, you do so entirely at your own risk and subject to the terms of use and privacy policies of such Linked Sites. Please be careful to read the terms of use and privacy policies of any Linked Sites before you provide any personal or other information to, or engage in any activity on, such Linked Sites. Any terms, conditions, warranties or representations included in the Linked Sites are solely between you and the relevant providers of the Linked Sites. You cannot rely on our Terms of Use and/or our Privacy Policy to govern your use of another website or destination.
            </Typography>
            <Typography variant='body1'>
                We do not endorse, and Atlaspet expressly disclaims responsibility and liability for, Linked Sites, including with respect to the content, products and services provided by the Linked Sites. Any interactions you have with Linked Sites are between you and the Linked Sites and you agree that Atlaspet is not liable for any damage or loss you may suffer as a result of any interactions with any such Linked Sites or any claims that you may have against any such Linked Sites.
            </Typography>
            <Typography variant='body1'>
                The Website and its content, features and functionality, including, without limitation, information, software, text, graphics, logos, button icons, images, audio clips, video clips, data compilations and the design, selection and arrangement thereof, are the exclusive property of Atlaspet A.Ş., our licensors or other content suppliers, and are protected by Republic of Turkey and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws, and may not be used or exploited in any way without our prior written consent.

            </Typography>
            <Typography variant='body1'>
                We are pleased to grant you a non-exclusive, revocable, limited license to use the Website solely for your personal and non-commercial use. You may only use the Website in the manner in which the Content (as defined below) you access is intended and in compliance with these Terms of Use and with any and all applicable local and international laws, rules and regulations. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, commercially exploit, create derivative works from, transfer or sell any Content, software, products or services contained with the Website without the express written consent of Atlaspet A.Ş.. You may not use the Website or any of its Content to further any commercial purpose, including any advertising or advertising revenue generation active on your own website or in any other form or medium. All rights not expressly granted to you in these Terms of Use are reserved and retained by Atlaspet A.Ş. and/or its Content providers. The licenses granted by Atlaspet A.Ş. terminate if you do not comply with these Terms of Use and/or any other Website terms and conditions.

            </Typography>
            <Typography variant='body1'>
                We reserve the right, in our sole discretion, to modify or update these Terms of Use at any time for any reason, with or without prior notice to you, and any such changes to these Terms of Use will supersede and replace any previous Terms of Use effective immediately upon posting to this page. It is your responsibility to periodically review these Terms of Use as posted here for any changes. Your continued use of the Website after the posting of changes to these Terms of Use will constitute your acceptance of such changes. For the avoidance of doubt, you should discontinue using the Website if you do not agree with any changes to these Terms of Use.

            </Typography>






        </Grid>
    </Layout>)
}
export default TermsOfUse